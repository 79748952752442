import { Component, OnInit } from '@angular/core';
import { ApiService, TranslatorService} from '../../../service';
import { Config} from '../../../guard';
import { LazyLoadEvent, ConfirmationService} from 'primeng/primeng'
import { MessageService} from 'primeng/api';
import { Validators, FormBuilder, FormGroup} from '@angular/forms';

@Component({
	selector: 'app-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.css'],
	providers: [ConfirmationService]
})
export class UserComponent implements OnInit {
	dataTable: any[]
	totalRecords: number
	loading: boolean
	column: any
	sort: any
	page: number
	rows: number
	pencarian: string
	selected: any[]
	aktivIndex: number
	form: FormGroup
	d_kelompok_user: any[]
	d_cabang: any[]
	imgSrc: any
	listCabang: any[]
	listKelompokUser: any[]
	IDCabang: any
	IDKelompokUser: any
	IDKelompokUser_cari: any[]
	IDCabang_cari: any[]
	NamaLengkap_cari: any[]
	constructor(private fb: FormBuilder,
		private apiService: ApiService,
		private messageService: MessageService,
		private terjemah: TranslatorService,
		private confirmationService: ConfirmationService) { }

	ngOnInit() {
		this.aktivIndex = 0
		this.page = Config.get().page
		this.rows = Config.get().rows
		this.column = ''
		this.sort = ''
		this.pencarian = ''
		this.getDataTable(this.column, this.sort, this.page, this.rows, this.pencarian)
		this.getDrodown()
		this.getDataCabang()
		this.form = this.fb.group({
			IDUser : [''],
			IDKontak : [''],
			IDCabang: ['',Validators.required],
			IDKelompokUser: ['',Validators.required],
			NamaLengkap: ['',Validators.required],
			UserEmail: ['',Validators.required],
			UserPhone: ['',Validators.required],
			UserPassword: ['',Validators.required],
			KonfirmPassword: ['']
		})
		this.imgSrc = "../../../assets/layout/images/default-photo.png"
	}
	cari(){
		this.getDataTable(this.column, this.sort, this.page, this.rows, this.pencarian)
	}
	getDrodown(){
		this.apiService.get(Config.get().apiBackend+'service/setting/user/kelompok-user').subscribe((res)=>{
			this.d_kelompok_user = []
			this.listKelompokUser = []
			this.listKelompokUser.push({
				label:'Semua Data',
				value: ''
			})
			res.data.forEach((data : any)=>{
				this.d_kelompok_user.push({
					label:data.KelompokUser,
					value:data.IDKelompokUser
				})

				this.listKelompokUser.push({
					label:data.KelompokUser,
					value:data.IDKelompokUser
				})
			})
		})
	}

	getDataCabang (){
		this.apiService.get(Config.get().apiBackend + 'service/transaksi/upload-data/combo-cabang').subscribe((res: any) => {
				this.listCabang = []
				this.d_cabang = []
				this.listCabang.push({label: 'Semua Data', value: ''})
				res.data.forEach((data: any) => {
						this.listCabang.push({label: data.NamaCabang, value: data.IDCabang})
						this.d_cabang.push({label: data.NamaCabang, value: data.IDCabang})
				})
		})
	}
	getDataTable(column,sort,page,rows,pencarian) {
		this.loading = true;

		this.apiService.get(Config.get().apiBackend+'service/setting/user/all?dir='+column+'&sort='+sort+'&page='+page+'&rows='+rows+'&condition='+pencarian+'&IDKelompokUser='+this.IDKelompokUser_cari+'&IDCabang='+this.IDCabang_cari+'&NamaLengkap='+this.NamaLengkap_cari).subscribe((res)=>{
			this.dataTable = res.data
			this.totalRecords = res.totalRow
			this.loading = false
		}, (_error: any) => {
				this.loading = false
		})
	}

	onRowDblClick(event, data){
		this.aktivIndex = 1;
		console.log('cek-data-user:', data);
		let dataGrid = {
				IDUser: data.IDUser,
				IDKontak : data.IDKontak,
				NamaLengkap: data.NamaLengkap,
				IDCabang: data.IDCabang,
				IDKelompokUser: data.IDKelompokUser,
				UserEmail: data.UserEmail,
				UserPhone : data.UserPhone,
				UserPassword : data.UserPassword,
				KonfirmPassword : data.UserPassword
		};
		this.form.setValue(dataGrid)
	}

	lazyLoad(event: LazyLoadEvent) {
		let sort
		if (event.sortOrder == 1) {
			sort = 'asc'
		} else {
			sort = 'desc'
		}
		console.log(event);
		if (event.sortField != undefined) {
			this.getDataTable(event.sortField,sort,event.first,event.rows,this.pencarian)
		}else{
			this.getDataTable(event.sortField,sort,event.first,event.rows,this.pencarian)
		}
	}

	setAktifIndex(event) {
		this.aktivIndex = event.index
	}

	onBasicUpload(event){
		this.imgSrc = window.URL.createObjectURL(event.files[0])
	}

	onSubmit(){
		this.save();
		// if (this.form.invalid) {
		// 	console.log('testing');
		// 		this.messageService.add({
		// 				key: 't-notif',
		// 				severity: 'warn',
		// 				summary: this.terjemah.getTranslate().kesalahan,
		// 				detail: this.terjemah.getTranslate().pesanKesalahanDataTidakLengkap
		// 		});
		// } else {
				// this.save();
		//}
	}

	batal(){
		this.aktivIndex = 0
		this.reset();
	}

	reset(){
		this.form.reset({
			IDuser:'',
			IDKontak: '',
			IDCabang: '',
			IDKelompokUser: '',
			NamaLengkap: '',
			UserEmail: '',
			UserPhone: '',
			UserPassword: '',
			KonfirmPassword: ''
		})
	}

	save() {
			// this.confirmationService.confirm({
			// 		message: 'Simpan data?',
			// 		accept: () => {
							let data = this.form.value;
							let dataKirim = {
									IDUser : data.IDUser,
									IDKontak : data.IDKontak,
									IDCabang: data.IDCabang,
									IDKelompokUser: data.IDKelompokUser,
									NamaLengkap: data.NamaLengkap,
									UserEmail: data.UserEmail,
									UserPhone: data.UserPhone,
									UserPassword: data.UserPassword
							}
							this.apiService.add(Config.get().apiBackend + 'service/setting/user/manage', dataKirim).subscribe(_res => {
									this.messageService.add({
											key: 't-notif',
											severity: 'success',
											summary: this.terjemah.getTranslate().sukses,
											detail: this.terjemah.getTranslate().pesanSuksesInputData
									});
									this.ngOnInit();
									this.aktivIndex = 0
									this.getDataTable('','',0, 10,'')
							});
			// 		}
			// });
	}

	hapus() {

			// this.confirmationService.confirm({
			// 		message: 'Simpan data?',
			// 		accept: () => {
							let dataDelete = []
							for (let i = 0; i < this.selected.length; i++) {
									dataDelete.push(this.selected[i].IDUser)
							}
							let dataKirim = {
									IDUser : dataDelete,
							}
							this.apiService.add(Config.get().apiBackend + 'service/setting/user/delete', dataKirim).subscribe(_res => {
									this.messageService.add({
											key: 't-notif',
											severity: 'success',
											summary: this.terjemah.getTranslate().sukses,
											detail: this.terjemah.getTranslate().pesanSuksesHapusData
									});
									this.batal();
							});
							// this.apiService.add(Config.get().apiBackend + 'service/setting/user/delete', dataKirim).subscribe(_res => {
							// 		this.messageService.add({
							// 				key: 't-notif',
							// 				severity: 'success',
							// 				summary: this.terjemah.getTranslate().sukses,
							// 				detail: this.terjemah.getTranslate().pesanSuksesInputData
							// 		});
							// 		this.ngOnInit();
							// 		this.aktivIndex = 0
							// 		this.getDataTable('','',0, 10,'')
							// });
			// 		}
			// });
	}

	tambah(){
		this.reset()
		this.aktivIndex = 1
	}
}
