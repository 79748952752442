import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthService, TranslatorService } from '../../service';
import { MessageService } from 'primeng/api';

@Component({
	selector: 'app-survey',
	templateUrl: './survey.component.html',
	styleUrls: ['./survey.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class SurveyComponent implements OnInit {
	items: any[];
	index: number = 0;

    openNext() {
        this.index = (this.index === 2) ? 0 : this.index + 1;
    }

    openPrev() {
        this.index = (this.index === 0) ? 2 : this.index - 1;
    }
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		private authService: AuthService,
		private terjemah: TranslatorService,
		private messageService: MessageService) {  }

	ngOnInit() {
		this.items = [
            {komponen: 'Apakah toko ini bagus cuy asdf fsafddfd adsfajf asdfks adsfas kfasldf ?', year: 2010, brand: 'Audi', color: 'Black'},
            {komponen: 'jhto2g2', year: 2015, brand: 'BMW', color: 'White'},
            {komponen: 'h453w54', year: 2012, brand: 'Honda', color: 'Blue'},
            {komponen: 'g43gwwg', year: 1998, brand: 'Renault', color: 'White'},
            {komponen: 'gf45wg5', year: 2011, brand: 'VW', color: 'Red'},
            {komponen: 'bhv5y5w', year: 2015, brand: 'Jaguar', color: 'Blue'},
            {komponen: 'ybw5fsd', year: 2012, brand: 'Ford', color: 'Yellow'},
            {komponen: '45665e5', year: 2011, brand: 'Mercedes', color: 'Brown'},
            {komponen: 'he6sb5v', year: 2015, brand: 'Ford', color: 'Black'}
        ];
	}

	selectCar() {
        this.messageService.add({severity: 'info', summary: 'Car Selected'});
    }
}
