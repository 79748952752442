export class Config {
	static get() {
		return {
		    // apiBackend2: "http://localhost:8000/",
		    apiBackend: 'https://service.radanka.com/',
			// apiBackend: 'http://localhost:3000/',
			page:1,
			rows:10
		}
	}
}
