import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService, TranslatorService, LoaderService } from '../../service';
import { Config } from '../../guard';
import { LazyLoadEvent,ConfirmationService } from 'primeng/primeng';
import { Validators, FormBuilder, FormGroup} from '@angular/forms';
import { MessageService } from 'primeng/api';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
	selector: 'app-bi',
	templateUrl: './bi.component.html',
	styleUrls: ['./bi.component.css'],
	providers: [ConfirmationService],
	encapsulation: ViewEncapsulation.None,
})
export class BiComponent implements OnInit {
  	dataTable: any[];
	totalRecords: number;
	loading: boolean;
	column: any = '';
	sort: any = '';
	page: number = Config.get().page;
	rows: number = Config.get().rows;
	pencarian: string = '';
  	filter: FormGroup;
	display: boolean = false;
  	d_province: any[];
	d_district: any[];
	d_sub_district: any[]
	d_village: any[]
	dataPetugas: any[];
	dataPemilik: any[];
  	dataProgram: any[];
	dataRumpun: any[];
	dataGender: any[];
	dataJenisTernak: any[];
  	rangeDates: any;
	cols: any[];
	lines: any[];
	line1:any[];
	line2:any[];
	line3:any[];
	line4:any[];
	line5:any[];
	line6:any[];
	line7:any[];
	dataSummary: any[];
	totalSummary: number;
	loadingSummary: boolean;
	columnSummary: any = '';
	sortSummary: any = '';
	pageSummary: number = Config.get().page;
	rowsSummary: number = Config.get().rows;
	pencarianSummary: string = '';
	colsSummary: any[];
	pie_gender: any;
	pie_gender_pemilik: any;
	bar_program: any;
	bar_daily: any;
	bar_sum_product: any;
	bar_umur_ternak: any;
	bar_umur_pemilik: any;
	options: any;
	optionsBar: any;
	optionsDaily: any;
	optionsGenderPemilik: any;
	optionsSumProduct: any;
	optionsUmurTernak: any;
	optionsUmurPemilik: any;
	rangeValues: number[] = [0,1200];
	rangeValuesPemilik: number[] = [0,100];
	headerTitle: any = "PROVINSI";
	srcUrlPopulasi;
	displayDialog:boolean = true
	constructor(private fb: FormBuilder,
		private apiService: ApiService,
		private messageService: MessageService,
		private terjemah: TranslatorService,
		private confirmationService: ConfirmationService,
		private domSanitizer : DomSanitizer) {
	}

ngOnInit() {
    // this.loadAllCombo();
	this.filter = this.fb.group({
		id_province:[''],
		id_district: [''],
		id_sub_district:[''],
		id_village:[''],
		id_category:[''],
		id_rumpun:[''],
		jenis_kelamin: [''],
		program: [''],
		id_pemilik:[''],
		umur:[''],
		umur_pemilik:[''],
		created_at: ['']
	});

	this.cols = [
		{header: this.headerTitle, field: "description", width:"", align:"left"}
	]

	this.displayDialog = true
	this.colsSummary = [
		{header: "DESKRIPSI", field: "company_name", width:"250px", align:"left",align_header:"center" },
		{header: "JNS TERNAK", field: "product_type", width:"100px", align:"left",align_header:"center"},
		{header: "PERAH", field: "perah", width:"100px", align:"center",align_header:"center"},
		{header: "POTONG", field: "penggemukan", width:"100px", align:"center",align_header:"center"},
		{header: "PEMBIAKAN", field: "pembiakan", width:"100px", align:"center",align_header:"center"},
		{header: "PEMBIBITAN", field: "pembibitan", width:"100px", align:"center",align_header:"center"},
		{header: "PEMBENIHAN", field: "pembenihan", width:"100px", align:"center",align_header:"center"},
		{header: "TOTAL", field: "total", width:"100px", align:"center",align_header:"center"}
	]
	// this.getData();
	// this.getData2();
	// this.getGraph();
	// this.getGraph2();
	// this.getGraph3();
	// this.getLocation();
	this.getPowerBi();
  }

  	loadAllCombo(){
    	this.comboDataProvince();
		this.comboDataJenisTernak();
		this.comboDataProgram();
		this.comboDataRumpun();
		this.comboDataGender();
		this.comboDataPemilik()
		this.comboDataPetugas();
	}

  resetFilter(){
		let today = new Date();
		let firstDate = new Date();
		this.filter.reset({
			id_province:'',
			id_district: '',
			id_sub_district:'',
			id_village:'',
			id_category:'',
			id_rumpun:'',
			jenis_kelamin: '',
			program: '',
			id_pemilik:'',
			umur:[0,120],
			umur_pemilik:[0,100],
			created_at: ''
     	 });
	}
	

	cari(){
		this.display = false;
		this.getData();
		this.getData2();
		this.getLocation();
		this.getGraph();
		this.getDataTable('company_name','asc',0,10, this.filter.value);
	}


	async getData():Promise<void> {
		this.loading = true;
		var cari = '';
		var date_create = [];
		var pencarian = this.filter.value;
		if(pencarian){
			if(pencarian.created_at){
				let start_date = this.formatTanggal(pencarian.created_at[0]);
				let end_date = this.formatTanggal(pencarian.created_at[1]);
				date_create = [start_date, end_date];
			}else{
				date_create = [];
			}

			cari += '&id_province='+pencarian.id_province;
			cari += '&id_district='+pencarian.id_district;
			cari += '&id_sub_district='+pencarian.id_sub_district;
			cari += '&id_village='+pencarian.id_village;
			cari += '&id_category='+pencarian.id_category;
			cari += '&id_rumpun='+pencarian.id_rumpun;
			cari += '&jenis_kelamin='+pencarian.jenis_kelamin;
			cari += '&program='+pencarian.program;
			cari += '&id_pemilik='+pencarian.id_pemilik;
			cari += '&umur='+pencarian.umur;
			cari += '&umur_pemilik='+pencarian.umur_pemilik;
			cari += '&created_at='+date_create;
		}
		this.apiService.get(Config.get().apiBackend+'api/admin/dashboard/all-display?'+cari).subscribe((res)=>{	
			this.line1 = res.data.line1
			this.line2 = res.data.line2
			this.loading = false
		}, (_error: any) => {
				this.loading = false
		})
	}

	async getData2():Promise<void> {
		this.loading = true;
		var cari = '';
		var date_create = [];
		var pencarian = this.filter.value;
		if(pencarian){
			if(pencarian.created_at){
				let start_date = this.formatTanggal(pencarian.created_at[0]);
				let end_date = this.formatTanggal(pencarian.created_at[1]);
				date_create = [start_date, end_date];
			}else{
				date_create = [];
			}

			cari += '&id_province='+pencarian.id_province;
			cari += '&id_district='+pencarian.id_district;
			cari += '&id_sub_district='+pencarian.id_sub_district;
			cari += '&id_village='+pencarian.id_village;
			cari += '&id_category='+pencarian.id_category;
			cari += '&id_rumpun='+pencarian.id_rumpun;
			cari += '&jenis_kelamin='+pencarian.jenis_kelamin;
			cari += '&program='+pencarian.program;
			cari += '&id_pemilik='+pencarian.id_pemilik;
			cari += '&umur='+pencarian.umur;
			cari += '&umur_pemilik='+pencarian.umur_pemilik;
			cari += '&created_at='+date_create;
		}
		this.apiService.get(Config.get().apiBackend+'api/admin/dashboard/all-display2?'+cari).subscribe((res)=>{	
			this.line3 = res.data.line3
			this.line5 = res.data.line5
			this.line6 = res.data.line6
			this.line7 = res.data.line7
			this.loading = false
		}, (_error: any) => {
				this.loading = false
		})
	}

	async getGraph2():Promise<void> {
		this.loading = true;
		var cari = '';
		var date_create = [];
		var pencarian = this.filter.value;
		if(pencarian){
			if(pencarian.created_at){
				let start_date = this.formatTanggal(pencarian.created_at[0]);
				let end_date = this.formatTanggal(pencarian.created_at[1]);
				date_create = [start_date, end_date];
			}else{
				date_create = [];
			}

			cari += '&id_province='+pencarian.id_province;
			cari += '&id_district='+pencarian.id_district;
			cari += '&id_sub_district='+pencarian.id_sub_district;
			cari += '&id_village='+pencarian.id_village;
			cari += '&id_category='+pencarian.id_category;
			cari += '&id_rumpun='+pencarian.id_rumpun;
			cari += '&jenis_kelamin='+pencarian.jenis_kelamin;
			cari += '&program='+pencarian.program;
			cari += '&id_pemilik='+pencarian.id_pemilik;
			cari += '&umur='+pencarian.umur;
			cari += '&umur_pemilik='+pencarian.umur_pemilik;
			cari += '&created_at='+date_create;
		}
		this.apiService.get(Config.get().apiBackend+'api/admin/dashboard/all-graph2?'+cari).subscribe((res)=>{	
			this.barDaily(res.data.bar_daily);
			this.barSumProduct(res.data.bar_sum_product);
			this.loading = false
		}, (_error: any) => {
				this.loading = false
		})
	}

	async getGraph():Promise<void> {
		this.loading = true;
		var cari = '';
		var date_create = [];
		var pencarian = this.filter.value;
		if(pencarian){
			if(pencarian.created_at){
				let start_date = this.formatTanggal(pencarian.created_at[0]);
				let end_date = this.formatTanggal(pencarian.created_at[1]);
				date_create = [start_date, end_date];
			}else{
				date_create = [];
			}

			cari += '&id_province='+pencarian.id_province;
			cari += '&id_district='+pencarian.id_district;
			cari += '&id_sub_district='+pencarian.id_sub_district;
			cari += '&id_village='+pencarian.id_village;
			cari += '&id_category='+pencarian.id_category;
			cari += '&id_rumpun='+pencarian.id_rumpun;
			cari += '&jenis_kelamin='+pencarian.jenis_kelamin;
			cari += '&program='+pencarian.program;
			cari += '&id_pemilik='+pencarian.id_pemilik;
			cari += '&umur='+pencarian.umur;
			cari += '&umur_pemilik='+pencarian.umur_pemilik;
			cari += '&created_at='+date_create;
		}
		this.apiService.get(Config.get().apiBackend+'api/admin/dashboard/all-graph?'+cari).subscribe((res)=>{	
			this.pieGender(res.data.pie_gender);
			this.pieGenderPemilik(res.data.pie_gender_pemilik);
			this.barProgram(res.data.bar_program);
			this.loading = false
		}, (_error: any) => {
				this.loading = false
		})
	}

	async getGraph3():Promise<void> {
		this.loading = true;
		var cari = '';
		var date_create = [];
		var pencarian = this.filter.value;
		if(pencarian){
			if(pencarian.created_at){
				let start_date = this.formatTanggal(pencarian.created_at[0]);
				let end_date = this.formatTanggal(pencarian.created_at[1]);
				date_create = [start_date, end_date];
			}else{
				date_create = [];
			}

			cari += '&id_province='+pencarian.id_province;
			cari += '&id_district='+pencarian.id_district;
			cari += '&id_sub_district='+pencarian.id_sub_district;
			cari += '&id_village='+pencarian.id_village;
			cari += '&id_category='+pencarian.id_category;
			cari += '&id_rumpun='+pencarian.id_rumpun;
			cari += '&jenis_kelamin='+pencarian.jenis_kelamin;
			cari += '&program='+pencarian.program;
			cari += '&id_pemilik='+pencarian.id_pemilik;
			cari += '&umur='+pencarian.umur;
			cari += '&umur_pemilik='+pencarian.umur_pemilik;
			cari += '&created_at='+date_create;
		}
		this.apiService.get(Config.get().apiBackend+'api/admin/dashboard/all-graph3?'+cari).subscribe((res)=>{	
			this.barUmurTernak(res.data.bar_umur_ternak);
			this.barUmurPemilik(res.data.bar_umur_pemilik);
			this.loading = false
		}, (_error: any) => {
				this.loading = false
		})
	}

	async getLocation():Promise<void> {
		this.loading = true;
		var cari = '';
		var date_create = [];
		var pencarian = this.filter.value;
		if(pencarian){
			if(pencarian.created_at){
				let start_date = this.formatTanggal(pencarian.created_at[0]);
				let end_date = this.formatTanggal(pencarian.created_at[1]);
				date_create = [start_date, end_date];
			}else{
				date_create = [];
			}

			if(pencarian.id_province){
				this.headerTitle = "KABUPATEN"
			}

			if(pencarian.id_district){
				this.headerTitle = "KECAMATAN"
			}

			if(pencarian.id_sub_district){
				this.headerTitle = "DESA"
			}

			cari += '&id_province='+pencarian.id_province;
			cari += '&id_district='+pencarian.id_district;
			cari += '&id_sub_district='+pencarian.id_sub_district;
			cari += '&id_village='+pencarian.id_village;
			cari += '&id_category='+pencarian.id_category;
			cari += '&id_rumpun='+pencarian.id_rumpun;
			cari += '&jenis_kelamin='+pencarian.jenis_kelamin;
			cari += '&program='+pencarian.program;
			cari += '&id_pemilik='+pencarian.id_pemilik;
			cari += '&umur='+pencarian.umur;
			cari += '&umur_pemilik='+pencarian.umur_pemilik;
			cari += '&created_at='+date_create;
		}
		this.apiService.get(Config.get().apiBackend+'api/admin/dashboard/all-location?'+cari).subscribe((res)=>{
			this.dataTable = res.data
			this.loading = false
		}, (_error: any) => {
				this.loading = false
		})
	}

	pieGender(data){
		this.options = {
			title: {
			  display: true,
			  text: 'JENIS KELAMIN TERNAK',
			  fontSize: 24
			},
			legend: {
			  position: 'bottom'
			}
		  }

		this.pie_gender = {
			labels: data.labels,
			datasets: [
				{
					data: data.values,
					backgroundColor: data.colours,
					hoverBackgroundColor:data.colours
				}
			]    
		};
	}

	pieGenderPemilik(data){
		this.optionsGenderPemilik = {
			title: {
			  display: true,
			  text: 'JENIS KELAMIN PEMILIK INDIVIDU',
			  fontSize: 24
			},
			legend: {
			  position: 'bottom'
			}
		  }

		this.pie_gender_pemilik = {
			labels: data.labels,
			datasets: [
				{
					data: data.values,
					backgroundColor: data.colours,
					hoverBackgroundColor:data.colours
				}
			]    
		};
	}

	barProgram(data){
		this.optionsBar = {
			title: {
			  display: true,
			  text: 'PROGRAM PEMELIHARAAN',
			  fontSize: 24
			},
			legend: {
			  position: 'bottom'
			}
		}

		this.bar_program = {
            labels: data.labels,
            datasets: [
                {
                    label: 'PROGRAM PEMELIHARAAN',
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: data.values
                }
            ]
        }
	}

	barDaily(data){
		this.optionsDaily = {
			title: {
			  display: true,
			  text: 'HASIL PENDATAAN HARIAN',
			  fontSize: 24
			},
			legend: {
			  position: 'bottom'
			}
		}

		this.bar_daily = {
            labels: data.labels,
            datasets: [
                {
                    label: 'HASIL PENDATAAN HARIAN',
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: data.values
                }
            ]
        }
	}

	barSumProduct(data){
		this.optionsSumProduct = {
			title: {
			  display: true,
			  text: 'JUMLAH KEPEMILIKAN TERNAK INDIVIDU',
			  fontSize: 24
			},
			legend: {
			  position: 'bottom'
			}
		}

		this.bar_sum_product = {
            labels: data.labels,
            datasets: [
                {
                    label: 'JUMLAH KEPEMILIKAN TERNAK INDIVIDU',
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: data.values
                }
            ]
        }
	}

	barUmurTernak(data){
		this.optionsUmurTernak = {
			title: {
			  display: true,
			  text: 'UMUR TERNAK',
			  fontSize: 24
			},
			legend: {
			  position: 'bottom'
			}
		}

		this.bar_umur_ternak = {
            labels: data.labels,
            datasets: [
                {
                    label: 'UMUR TERNAK',
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: data.values
                }
            ]
        }
	}

	barUmurPemilik(data){
		this.optionsUmurPemilik = {
			title: {
			  display: true,
			  text: 'KELOMPOK USIA PEMILIK INDIVIDU',
			  fontSize: 24
			},
			legend: {
			  position: 'bottom'
			}
		}

		this.bar_umur_pemilik = {
            labels: data.labels,
            datasets: [
                {
                    label: 'KELOMPOK USIA PEMILIK INDIVIDU',
                    backgroundColor: '#42A5F5',
                    borderColor: '#1E88E5',
                    data: data.values
                }
            ]
        }
	}

	async comboDataJenisTernak():Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'api/product/jenis-ternak').subscribe((res: any) => {
			this.dataJenisTernak = []
			res.data.forEach((data: any) => {
				this.dataJenisTernak.push({label: data.label, value: data.value})
			})
		})
	}

	async comboDataProgram():Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'api/product/program').subscribe((res: any) => {
			this.dataProgram = []
			res.data.forEach((data: any) => {
				this.dataProgram.push({label: data.label, value: data.value})
			})
		})
	}

	async comboDataRumpun():Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'api/product/rumpun').subscribe((res: any) => {
			this.dataRumpun = []
			res.data.forEach((data: any) => {
				this.dataRumpun.push({label: data.label, value: data.value})
			})
		})
	}

	async comboDataGender():Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'api/product/jenis-kelamin-ternak' ).subscribe((res: any) => {
			this.dataGender = []
			res.data.forEach((data: any) => {
				this.dataGender.push({label: data.label.toUpperCase(), value: data.value})
			})
		})
	}

	async comboDataPetugas():Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'api/admin/combo-petugas').subscribe((res: any) => {
			this.dataPetugas = []
			res.data.forEach((data: any) => {
				this.dataPetugas.push({label: data.label.toUpperCase(), value: data.value})
			})
		})
	}

	async comboDataPemilik():Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'api/admin/combo-pemilik').subscribe((res: any) => {
			this.dataPemilik = []
			res.data.forEach((data: any) => {
				this.dataPemilik.push({label: data.name.toUpperCase()+" - " + data.nik, value: data.id})
			})
		})
	}

	async comboDataProvince():Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'api/address/province?id_country=1').subscribe((res: any) => {
			this.d_province = []
			res.data.forEach((data: any) => {
				this.d_province.push({label: data.province, value: data.id})
			})
		})
	}

	async comboDataDistrict(id_province : any):Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'api/address/district?id_province='+id_province).subscribe((res: any) => {
			this.d_district = []
			res.data.forEach((data: any) => {
				this.d_district.push({label: data.district, value: data.id})
			})
		})
	}

	async comboDataSubDistrict(id_district: any):Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'api/address/sub-district?id_district='+id_district).subscribe((res: any) => {
			this.d_sub_district = []
			res.data.forEach((data: any) => {
				this.d_sub_district.push({label: data.sub_district, value: data.id})
			})
		})
	}

	async comboDataVillage(id_sub_district: any):Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'api/address/village?id_sub_district=' + id_sub_district ).subscribe((res: any) => {
			this.d_village = []
			res.data.forEach((data: any) => {
				this.d_village.push({label: data.urban_village, value: data.id})
			})
		})
	}

	onChageProvince($event){
		this.comboDataDistrict($event.value);
	}

	onChageDistrict($event){
		this.comboDataSubDistrict($event.value);
	}

	onChageSubDistrict($event){
		this.comboDataVillage($event.value);
	}

	formatTanggal(date_: any) {
		console.log('tes', date_)
		let date = new Date(date_);
        let tanggal = ('0' + date.getDate()).slice(-2)
        let bulan = ('0' + (date.getMonth() + 1)).slice(-2)
        let tahun = date.getFullYear()
        let format = tahun + '-' + bulan + '-' + tanggal
        return format;
    }

	lazyLoad(event: LazyLoadEvent) {
		let sort
		if (event.sortOrder == 1) {
			sort = 'asc'
		} else {
			sort = 'desc'
		}

		if (event.sortField != undefined) {
			this.getDataTable(event.sortField,sort,event.first,event.rows,this.filter.value)
		}else{
			console.log(this.filter.value)
			this.getDataTable('company_name',sort,event.first,event.rows,this.filter.value)
		}
	}

	getDataTable(column,sort,page,rows,pencarian) {
		this.loadingSummary = true;
		var date_create = [];
		var cari = '';
		if(pencarian){
			if(pencarian.created_at){
				let start_date = this.formatTanggal(pencarian.created_at[0]);
				let end_date = this.formatTanggal(pencarian.created_at[1]);
				date_create = [start_date, end_date];
			}else{
				date_create = [];
			}

			cari += '&id_province='+pencarian.id_province;
			cari += '&id_district='+pencarian.id_district;
			cari += '&id_sub_district='+pencarian.id_sub_district;
			cari += '&id_village='+pencarian.id_village;
			cari += '&id_category='+pencarian.id_category;
			cari += '&id_rumpun='+pencarian.id_rumpun;
			cari += '&jenis_kelamin='+pencarian.jenis_kelamin;
			cari += '&program='+pencarian.program;
			cari += '&id_pemilik='+pencarian.id_pemilik;
			cari += '&umur='+pencarian.umur;
			cari += '&umur_pemilik='+pencarian.umur_pemilik;
			cari += '&created_at='+date_create;
		}
		this.apiService.get(Config.get().apiBackend+'api/admin/dashboard/all-unit-usaha?dir='+column+'&sort='+sort+'&page='+page+'&rows='+rows+cari).subscribe((res)=>{
			this.dataSummary = res.data
			this.totalSummary = res.totalRow
			this.loadingSummary = false
		}, (_error: any) => {
				this.loadingSummary = false
		})
	}

	async getPowerBi():Promise<void>{
		this.apiService.get(Config.get().apiBackend + 'power-bi-populasi').subscribe((res: any) => {
			this.srcUrlPopulasi = this.domSanitizer.bypassSecurityTrustResourceUrl(res.data);
		})
	}

}
