import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="footer clearfix">
        <span>Siapps Software Development, 2023</span>
    </div>
    `
})
export class AppFooterComponent {

}
