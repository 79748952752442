export * from "./dashboard/dashboard.component";
export * from "./bi/bi.component";
export * from "./not-found/not-found.component";
export * from "./access-denied/access-denied.component";
export * from "./login/login.component";
export * from "./survey/survey.component";
export * from "./master/user/user.component";
// export * from "./transaksi/lembar-kerja-sa/lembar-kerja-sa.component";
// export * from "./transaksi/lembar-kerja-sales/lembar-kerja-sales.component";
// export * from "./transaksi/lembar-kerja-mra/lembar-kerja-mra.component";
