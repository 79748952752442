import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent, NotFoundComponent, AccessDeniedComponent, LoginComponent,BiComponent} from './page';
import {AuthGuard, LoginGuard} from './guard';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [
        RouterModule.forRoot([
          {canActivate: [AuthGuard], path: '', component: DashboardComponent},
          {path: 'bi', component: BiComponent},
          {canActivate:[LoginGuard], path: 'login', component: LoginComponent},
          {canActivate:[AuthGuard], path: 'user', loadChildren: './page/master/user/user.module#UserModule'},
          {canActivate:[AuthGuard] ,path: 'kampanye', loadChildren: './page/vote/kampanye/fitur.module#KampanyeModule' },
          {canActivate:[AuthGuard] ,path: 'issue', loadChildren: './page/vote/issue/fitur.module#IssueModule' },
          {canActivate:[AuthGuard] ,path: 'hitung-cepat', loadChildren: './page/vote/hitung-cepat/fitur.module#HitungCepatModule' },
          {canActivate:[AuthGuard] ,path: 'notifikasi', loadChildren: './page/vote/notifikasi/fitur.module#NotifikasiModule' },
          {canActivate:[AuthGuard] ,path: 'pemilih', loadChildren: './page/vote/pemilih/fitur.module#PemilihModule' },
          {canActivate:[AuthGuard] ,path: 'pemilu', loadChildren: './page/vote/pemilu/fitur.module#PemiluModule' },
          {canActivate:[AuthGuard] ,path: 'pesaing', loadChildren: './page/vote/pesaing/fitur.module#PesaingModule' },
          {canActivate:[AuthGuard] ,path: 'relawan', loadChildren: './page/vote/relawan/fitur.module#RelawanModule' },
          {canActivate:[AuthGuard] ,path: 'tps', loadChildren: './page/vote/tps/fitur.module#TpsModule' },
          {canActivate:[AuthGuard] ,path: 'dapil', loadChildren: './page/vote/wilayah/fitur.module#WilayahModule' },
          {path: 'not-found', component: NotFoundComponent},
          {path: 'access-denied', component: AccessDeniedComponent},
          // kalo gak ada page not found
          { path: '**', redirectTo: 'not-found' }
        ])
    ],
    exports: [RouterModule]
})
export class AppRoutes {}
